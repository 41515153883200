import { motion } from "framer-motion";
import { navigate } from "gatsby-link";
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from "react-helmet";
import { Layout } from '../components';
import { API_URL } from "../constants";


// markup
const EmailCheck = ({ location }) => {

	/*****************
	Check if Pre-Sale
	*****************/
	const isPreSale = useRef(null);
	useEffect(() => {
		// Fetch à API para verificar se Pre-sale está activa
		fetch(`${API_URL}/presale/status`)
			.then((response) => response.json())
			.then((data) => {
				isPreSale.current = data.result.status;
			})
			.catch((e) => console.error(e));
	}, []);

	const _onValidationSuccess = () => {
		console.log('_onValidationSuccess', {'isPreSale.current': isPreSale.current});
		setIsEmailChecked(true);
		if (isPreSale.current == null) {
			// setTimeout(_onValidationSuccess, 100);
			return;
		}

		setTimeout(() => {
			if (isPreSale.current) {
				navigate('/pre-sale');
			} else {
				navigate('/client-area');
			}
		}, 4000);
	}

	/* Email validation */
	const [isEmailChecked, setIsEmailChecked] = useState(null);
	const [errorMessage, setErrorMessage] = useState('We are sorry but we could not confirm your email :/');

	useEffect(() => {
		let code = location.hash.indexOf('#') !== -1 ? location.hash.substr(1) : false;
		if (code) {
			// Fetch à API para verificar se Pre-sale está activa

			fetch(`${API_URL}/auth/account-activation`, {
				method: 'POST',
				body: JSON.stringify({
					activation_code: code,
				}),
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			})
				.then((response) => {
					console.log('Activation response:', response);

					return response.json();
				})
				.then((data) => {
					console.log('Activation data:', data);
					if (data.error) {
						let errorText;
						if (data.error.message) {
							errorText = data.error.message;
						} else {
							switch (data.error) {
								case 'invalid_user':
								case 'user_not_found':
									errorText = 'User not found';
									break;
								case 'invalid_activation_code':
									errorText = 'Invalid activation code';
									break;
								case 'user_already_active':
									return _onValidationSuccess();
								default:
									errorText = 'An error ocurred';
							}
						}
						setErrorMessage(errorText);
						setIsEmailChecked(false);
					} else {
						_onValidationSuccess();
					}
				})
				.catch((e) => {
					console.error(e);
					setIsEmailChecked(false);
				});
		} else {
			setIsEmailChecked(false);
		}
	}, [location.hash]);

	return (
		<Layout location={location}>

			<Helmet>
				<link href="/assets/css/login.css" rel="stylesheet" type="text/css" />
			</Helmet>

			<motion.main
				id={'email_check'}
			 	style={{ opacity:0 }}
				data-name="layout"

				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{
					type: "spring",
					mass: 0.35,
					stiffness: 75,
					duration: 0.5,
					delay: 1
				}}
			>

				<div className={'main-wrapper'}>

					<div className={'pre-sale-bg d-flex justify-content-center align-items-center'}>
						<div className={'pre-sale-hero container-fluid'}>
							<div className={'col-12 col-sm-8 offset-sm-2 col-xl-6 offset-xl-3'}>
								<div className={'row align-items-center'}>
									{ isPreSale ? (
										<>
											<div className={'title col-6'}>Pre-<br />sale</div>
											<div className={'sign-in col-6'}>Get early access to our artists' exclusive NFT artworks</div>
										</>
									) : (
										<>
											<div className={'title col-6'}>Sign<br />in</div>
											<div className={'sign-in col-6'}>Welcome to your private area, where you can access your purchases history and status</div>
										</>
									)}
								</div>
							</div>
						</div>
					</div>

					{ isEmailChecked == null ? (

						<>
							{/* WAITING CALLBACK */}
							<div className={'container-fluid loading'}>
								<div className={'text-center col-sm-10 offset-sm-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3'}>
									Loading...
								</div>
							</div>
						</>

					) : (

						<>

							{/* Success */}
							{ isEmailChecked ? (
								<motion.main
									className={'output my-5'}

									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{
										type: "spring",
										mass: 0.35,
										stiffness: 75,
										duration: 0.5
									}}
								>
									<div className={'email-validation container-fluid d-flex justify-content-center align-items-center flex-column mb-5'}>

										<div className={'message col-10 col-sm-8 col-md-6 col-xl-4'}>
											<img className={'icon-checked mb-2'} src={'/assets/img/icons/check_icon.svg'} alt={'check icon'} />
											<div className={'title my-2'}>
												Your email is confirmed!
											</div>
											<div className={'sub-title my-2 mb-5'}>
												You are now logged in and being redirected.
											</div>
										</div>

									</div>
								</motion.main>
							) : (
								<motion.main
									className={'output my-6'}

									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{
										type: "spring",
										mass: 0.35,
										stiffness: 75,
										duration: 0.5
									}}
								>
									<div className={'email-validation container-fluid d-flex justify-content-center align-items-center flex-column mb-5'}>

										<div className={'message col-10 col-sm-8 col-md-6 col-xl-4'}>
											<img className={'icon-checked mb-2'} src={'/assets/img/icons/cross_error.svg'} alt={'cross icon'} />
											<div className={'title my-2'}>
												{errorMessage}
											</div>
											<div className={'sub-title my-2 mb-5'}>
												Please repeat the sign-up and check your email input.
											</div>
										</div>

									</div>
								</motion.main>
							)}
						</>

					) }

				</div>

			</motion.main>
		</Layout >
	)
}

export default EmailCheck
